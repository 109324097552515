<template>
	<div>
		<GmapMap :center="center" :zoom="12" class="map" />
		<main>
			<div class="container">
				<div class="content">
					<div class="row">
						<div class="col-md-6">
							<h4>Our Address</h4>
							<div class="footer-item">
								<p>East Legon - Adjiringanor, Accra, Ghana</p>
								<p>
									Around Ability Square, off Dzen Ayor
									Junction
								</p>
								<p>P.O. Box GP13539, Accra</p>
								<p>Phone Number: 233558414611</p>
								<p>
									Email: eastbridgeintertionalschool@gmail.com
								</p>
							</div>
							<h4>Working Hours</h4>
							<ul>
								<li><em>Mondays-Fridays:</em> 7am-5pm</li>
								<li><em>Saturdays:</em> 9am-2pm</li>
							</ul>
						</div>
						<div class="col-md-6">
							<h4>Contact Us</h4>
							<div class="footer-item footer-contact">
								<form @submit.prevent="sendContactInfo">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<input
													type="text"
													placeholder="Your Name"
													class="form-control"
													required
												/>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<input
													type="text"
													placeholder="Email Address"
													class="form-control"
													required
												/>
											</div>
										</div>
									</div>
									<div class="form-group">
										<textarea
											class="form-control"
											placeholder="Compose message here ..."
											required
										/>
									</div>
									<button type="submit" class="btn btn-block">
										Send
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {
			center: { lat: 5.66121841151454, lng: -0.1393600234015913 },
		};
	},
	mounted() {
		// this.geolocate();
	},
	methods: {
		geolocate: function () {
			navigator.geolocation.getCurrentPosition((position) => {
				this.center = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};
			});
		},
	},
};
</script>